import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class ECarWidget extends BaseWidget<{ plantId: string; title: string }> {
  static readonly id = 'e-car';
  static readonly publicFriendly = true;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { plantId: string; title: string; currency: string }
  ) {
    return new ECarWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data?: { plantId: string; title: string }
  ) {
    super(injector, ECarWidget.id, ECarWidget.publicFriendly, metaLinkId, x, y, rows, cols, data);
    this.resolveComponent(async () => {
      const module = await import('./e-car.component');
      return Object.values(module)[0];
    });
  }

  resolveData(data: { plantId: string; title: string }): void {
    this.setData({ plantId: data.plantId, title: data.title });
  }
}
